<template>
	<div class="animated fadeIn">
		<b-card>
			<b-card-title><i class="icon-clock"></i> Delayed Transfer Report</b-card-title>
			<b-card-sub-title>Summary of delayed transfers across accounts</b-card-sub-title>
			<b-container fluid class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="my-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="my-2" visible>
							<b-card>
								<!-- Date Range -->
								<b-row no-gutters>
									<b-col lg="4" md="5" sm="12" class="mr-4">
										<b-form-group label="Date From">
											<b-form-datepicker name="Date From" v-model="filterBy.dateFrom" locale="en"
												reset-button label-reset-button="Clear"
												:date-format-options="dateFormatOptions"
												:date-disabled-fn="dateFromDisabled" v-validate="'required'" />
											<span v-show="errors.has('Date From')" class="help-block">
												{{ errors.first('Date From') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="5" sm="12" class="mr-4">
										<b-form-group label="Date To">
											<b-form-datepicker name="Date To" v-model="filterBy.dateTo" locale="en"
												reset-button label-reset-button="Clear"
												:date-format-options="dateFormatOptions"
												:date-disabled-fn="dateFromDisabled" v-validate="'required'" />
											<span v-show="errors.has('Date To')" class="help-block">
												{{ errors.first('Date To') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<!-- Source/Destination -->
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Source Company">
											<v-select class="style-chooser" label="text"
												:options="allSourceCompanyOptions" :reduce="(company) => company.value"
												v-model="filterBy.sourceCompany">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Destination Company">
											<v-select class="style-chooser" label="text"
												:options="allDestinationCompanyOptions"
												:reduce="(company) => company.value"
												v-model="filterBy.destinationCompany">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Maturity Status">
											<b-form-select v-model="filterBy.maturityStatus" :options="statusOptions" class="mr-2" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-4">
										<b-form-group label="Dispatch No."
											description="NOTE: Input the exact Dispatch No. to search">
											<b-form-input id="dispatchNo" name="Dispatch No" type="search"
												class="numFont" v-model="filterBy.dispatchNo"
												v-validate="getValidationParam(false, /^[A-Z]{2}-[0-9]{6}-[0-9]{1,4}$/)"
												placeholder="XX-YYMMMDD-000" autocomplete="off" />
											<span v-show="errors.has('Dispatch No')" class="help-block">
												{{ errors.first('Dispatch No') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Generate
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<div v-if="items.length > 0">
					<b-row>
						<b-col sm="6" md="3" class="mt-4 mb-2">
							<b-dropdown text=" Select Actions " variant="dark" slot="append">
								<b-dropdown-item>
									<json-excel :data="exportData" :fields="exportFields" type="xls"
										:name="fileName + '.xls'">
										Export Delayed Transfer Report in Excel
									</json-excel>
								</b-dropdown-item>
								<b-dropdown-item>
									<json-excel :data="exportData" :fields="exportFields" type="csv"
										:name="fileName + '.csv'">
										Export Delayed Transfer Report to CSV
									</json-excel>
								</b-dropdown-item>
							</b-dropdown>
						</b-col>

						<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
							<b-input-group prepend="Show" append="/ Page">
								<b-form-select :options="pageOptions" v-model="perPage" />
							</b-input-group>
						</b-col>
					</b-row>

					<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
						:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
						:sort-direction="sortDirection" responsive>
						<template v-slot:cell(dispatchNo)="row">
							<span class="numFont">
								{{ row.item["Dispatch No"] }}
							</span>
						</template>
						<template v-slot:cell(dateDeployed)="row">
							<span v-if="row.item['Date Deployed'].length > 0">
								{{ row.item["Date Deployed"] }}
							</span>
							<span v-else> - </span>
						</template>
						<template v-slot:cell(dateReceived)="row">
							<span v-if="row.item['Date Received'].length > 0">
								{{ row.item["Date Received"] }}
							</span>
							<span v-else> - </span>
						</template>
						<template v-slot:cell(dispatched)="row">
							<span class="numFont">
								{{ row.item["Dispatched"] }}
							</span>
						</template>
						<template v-slot:cell(received)="row">
							<span class="numFont">
								{{ row.item["Received"] }}
							</span>
						</template>
						<template v-slot:cell(maturity)="row">
							<span class="numFont">
								{{ row.item["Maturity"] }}
							</span>
						</template>
						<template v-slot:cell(maturityStatus)="row">
							<span v-if="row.item['MaturityStatus'] === 'On-Going'">
								<b-badge variant="success">{{ row.item['MaturityStatus'] }}</b-badge>
							</span>
							<span v-else>
								<b-badge variant="dark">{{ row.item['MaturityStatus'] }}</b-badge>
							</span>
						</template>
					</b-table>

					<b-row>
						<b-col md="8" sm="12" class="my-1">
							<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination align="right" :total-rows="totalRows" :per-page="perPage"
								v-model="currentPage" class="my-0" />
						</b-col>
					</b-row>
				</div>
				<div v-else>
					<b-card> No Results Found. </b-card>
				</div>

			</b-container>
		</b-card>
	</div>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';


// API
import dispatchApi from '@/api/dispatchApi';

// Others
import JsonExcel from 'vue-json-excel';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import moment from 'moment';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'delayed-transfer-report',
	components: {
		JsonExcel,
		Loading
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'dateDeployed',
					sortable: true,
				},
				{
					key: 'dateReceived',
					sortable: true,
				},
				{
					key: 'Source',
				},
				{
					key: 'Destination',
				},
				{
					key: 'Dispatch No',
					label: 'Dispatch No.'
				},
				{
					key: 'Dispatched',
				},
				{
					key: 'Received',
				},
                {
					key: 'Maturity',
					label: 'Maturity',
				},
                {
					key: 'maturityStatus',
					label: 'Status'
				}
			],

			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			dateFormatOptions: { ...config.dateFormatOptions },

			defaultFilterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				sourceCompany: { ...config.companyDefaultValue },
				destinationCompany: { ...config.companyDefaultValue },
				maturityStatus: null,
				statuses: ['In-Transit','Receiving','Received'],
				dispatchNo: '',
			},

			filterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				sourceCompany: { ...config.companyDefaultValue },
				destinationCompany: { ...config.companyDefaultValue },
				maturityStatus: null,
				statuses: ['In-Transit','Receiving','Received'],
				dispatchNo: '',
			},

			prevFilterBy: {},
			params: {},

			allCompaniesObj: {},
			allConnectedCompaniesObj: {},
			allConnectionsObj: {},
			allDispatchesObj: {},

			allSourceCompanyOptions: [],
			allDestinationCompanyOptions: [],
			statusOptions: [{
				value: null,
				text: ' - Please select - '
			},
			{
				value: 'On-Going',
				text: 'On-Going'
			},
			{
				value: 'Matured',
				text: 'Matured'
			}],

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,

			isLoading: false,
		};
	},
	computed: {
		dateFrom() {
			const dateTo = moment();
			const dateFrom = dateTo.add(-30, 'days');
			return dateFrom.format('YYYY-MM-DD');
		},
		dateTo() {
			return moment().format('YYYY-MM-DD');
		},

		exportData() {
			return this.items;
		},

		exportFields() {
			return {
				'Date Deployed': 'Date Deployed',
				'Date Received': 'Date Received',
				'Source': 'Source',
				'Destination': 'Destination',
				'Dispatch No.': 'Dispatch No',
				'Dispatched': 'Dispatched',
				'Received': 'Received',
				'Maturity': 'Maturity',
				'Status': 'MaturityStatus',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'Delayed-Transfer-Report-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	created() {
		// Set Default Source Company
		if (!this.isSuperAdmin) {
			this.defaultFilterBy.sourceCompany = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
		}

		// Set Default Date Range
		this.defaultFilterBy.dateFrom = this.dateFrom;
		this.defaultFilterBy.dateTo = this.dateTo;
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Show loading indicator
				this.isLoading = true;

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allConnectedCompaniesObj = { ...this.$store.getters.connectedCompanies };
				this.allConnectionsObj = { ...this.$store.getters.connections };

				// Create Source and Destination Company Options
				if (this.isSuperAdmin) {
					this.allSourceCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
					this.allDestinationCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				} else {
					this.allSourceCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
					this.allDestinationCompanyOptions = DropDownItemsUtil.retrieveCompanies(this.allConnectedCompaniesObj);
				}

				this.resetFilters();
				await this.retrieveData();
				
			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}

		}, config.timeout);
	},
	methods: {
		getValidationParam(isRequired, regex) {
			return {
				required: isRequired,
				regex: regex,
			};
		},
		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},
		validateFilter() {
			let isValid = true;

			if (_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo)) {
				this.$toaster.warning('Date From and Date To are required.');
				isValid = false;
			} else if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
				(!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
				this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
				isValid = false;
			} else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
				isValid = false;
			} else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90) {
				this.$toaster.warning('Invalid Date Range. Data range is allowed up to 90 days difference.');
				isValid = false;
			}

			return isValid;
		},

		async onFilterRequest() {
			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (!this.validateFilter()) {
				return;
			}

			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				this.retrieveData();
				this.prevFilter = { ...this.filterBy };
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };

				// reset validation
				this.$validator.reset();
				this.errors.clear();
			}
		},
		async retrieveData() {
			try {
				// show loading indicator
				this.isLoading = true;

				let filter = { ...this.filterBy };
				filter.fromTimestamp = DateUtil.startDateTimeStamp(new Date(filter.dateFrom));
				filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

				let view = this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY;

				filter.companyId = this.loggedUserCompany.id;
				const { data } = await dispatchApi.getDispatches(
					filter,
					view,
					this.loggedUser.id
				);

				this.allDispatchesObj = data.dispatches;

				if (_.isEmpty(this.allDispatchesObj)) {
					this.$toaster.warning('No Results found.');
				} else {
					this.processDispatches(this.allDispatchesObj);
				}

			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.2');
			} finally {
                // hide loading indicator
                this.isLoading = false;
            }
		},
		processDispatches(dispatches) {
			this.items = [];

			_.forEach(dispatches, dispatch => {
				if (this.isDelayedTransfer(dispatch)) {
					let item = {};

					item['Dispatch No'] = dispatch.dispatchNo;
					item['Source'] = DispatchUtil.getCompanyLocationDisplay(dispatch.source);
					item['Destination'] = DispatchUtil.getCompanyLocationDisplay(dispatch.destination);
					item['Date Deployed'] = this.getDisplayDateTime(dispatch.dateDeployed);
					item['Date Received'] = this.getDisplayDateTime(dispatch.dateReceived);
					item['Dispatched'] = DispatchUtil.getTotalExpectedQuantity(dispatch.assets);
					item['Received'] = DispatchUtil.getTotalActualQuantity(dispatch.assets);
					item['Maturity'] = this.getMaturity(dispatch);
					item['MaturityStatus'] = this.getMaturityStatus(dispatch);

					if (this.isIncluded(item)) {
						this.items.push(item);	
					}
				}
			});

			this.items = _.sortBy(this.items, ['dateCreated']);
			this.items.reverse();
			this.totalRows = this.items.length;
		},
		
		isDelayedTransfer(dispatch) {
			return ['In-Transit','Receiving','Received'].includes(dispatch.status) && dispatch.isDelayedTransfer;
		},
		isIncluded(dispatch) {
			let maturityStatus = this.filterBy.maturityStatus;
			if (maturityStatus && maturityStatus !== dispatch['MaturityStatus']) {
				return false
			}
			return true;
		},
		getMaturity(dispatch) {
			return DateUtil.getNoOfDays(dispatch.dateDeployed, new Date()) + ' day(s)';
		},
		getMaturityStatus(dispatch) {
			let maturityDays = DateUtil.getNoOfDays(dispatch.dateDeployed, new Date());
			let delayedTransferDays = dispatch.delayedTransferDays;
			return maturityDays <= delayedTransferDays ? 'On-Going' : 'Matured';
		},

		// UTILS
		getDisplayDateTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	}
};
</script>